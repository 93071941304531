import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { FontFamily } from "@styles/variables/FontFamily"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import { HeroStyle } from "@styles/organisms/Hero"
import HeroHead from "./HeroHead"
import BackgroundImage from "gatsby-background-image"
import { breadcrumbsShape } from "@components/utils/shapes"
import Heading from "@components/ui/Heading"

const HeroStyled = styled(HeroStyle)`
  position: relative;
  overflow: initial;
  padding-top: 100px;
  overflow: hidden;
  height: 100vh;
  background-color: var(--color-primary);

  ${breakpointsMedia(breakpoints.tablet)} {
    height: 750px;
  }

  .container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    height: 80%;
  }

  .hero-head .breadcrumbs {
    top: 10px;

    li:last-child,
    li:last-child a,
    li:after {
      color: var(--color-text-light);
    }
  }

  .hero-inner {
    color: var(--color-text-light);
    text-align: center;

    .title {
      margin-top: 0;
      margin-bottom: 16px;
    }

    .sub-title {
      color: var(--color-text-light);
      font-family: ${FontFamily.Heading};
      font-size: 23px;
      font-weight: 500;
      line-height: 36px;
    }

    .blurb {
      color: var(--color-text-light);
      font-family: ${FontFamily.BodyFont};
      font-size: 17px;
      line-height: 29px;
      width: 100%;
      max-width: 787px;
      margin: auto;
    }
  }

  .background-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;

      ${breakpointsMedia(breakpoints.tabletWide, "max")} {
        object-position: 33% 50%;
      }

      ${breakpointsMedia(breakpoints.tablet, "max")} {
        object-position: 25% 50%;
      }
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
`

export const PageHero = ({
  uri,
  title,
  pageTitle,
  subTitle,
  blurb,
  heroImage,
  breadcrumbs,
}) => {
  const crumbs = breadcrumbs.concat({
    title: pageTitle,
  })

  return (
    <HeroStyled className={"header-offset"}>
      {heroImage && (
        <>
          <BackgroundImage
            style={{ position: "absolute" }}
            className="background-image"
            {...heroImage.localFile.childImageSharp}
          />
          <div className="overlay" />
        </>
      )}

      <div className="container">
        <HeroHead
          breadcrumbs={crumbs}
          uri={uri}
          hasShare={false}
          hasHome={true}
        />
        <div className="hero-inner">
          {(title || pageTitle) && (
            <Heading data-aos="fade-up" data-aos-duration="1000">
              {title || pageTitle}
            </Heading>
          )}
          {subTitle && (
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
              className=""
            >
              <strong
                className="sub-title"
                dangerouslySetInnerHTML={{ __html: subTitle }}
              />
            </div>
          )}
          {blurb && (
            <div
              className="blurb "
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="400"
              dangerouslySetInnerHTML={{ __html: blurb }}
            />
          )}
        </div>
      </div>
    </HeroStyled>
  )
}

PageHero.propTypes = {
  nextCaseStudy: PropTypes.shape({
    uri: PropTypes.string,
  }),
  previousCaseStudy: PropTypes.shape({
    uri: PropTypes.string,
  }),
  breadcrumbs: breadcrumbsShape,
}

PageHero.defaultProps = {
  breadcrumbs: [],
}
