import { useStaticQuery, graphql } from "gatsby"

const useDownloadableReports = () => {
  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          reports {
            acfDownloadableReports {
              socialMediaCovidReport {
                title
                report {
                  localFile {
                    publicURL
                  }
                }
              }
              socialMediaCovidReportPopover {
                title
                report {
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return wp?.reports?.acfDownloadableReports
}

export default useDownloadableReports
