import React, { useEffect, useState } from "react"
import styled from "styled-components"
import useDownloadableReports from "@components/utils/useDownloadableReports"

import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import classnames from "classnames"
import Loadable from "@loadable/component"

const MailChimpDownloadAsset = Loadable(() =>
  import("../forms/MailChimpDownloadAsset")
)

const DownloadAssetPopupStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  overflow-y: scroll;

  opacity: 0;
  visibility: hidden;

  &.open-dl_popover {
    z-index: 1220;
    opacity: 1;
    visibility: visible;

    .dl_popover__con {
      margin-top: 0;
      opacity: 1;
    }
  }

  .dl_popover__con {
    max-width: 620px;
    background: white;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    position: relative;
    margin-top: 50px;
    opacity: 0;
    transition: all 300ms ease;

    &:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 96px 96px 0;
      border-color: transparent #fd0 transparent transparent;
      z-index: -1;
      position: absolute;
      top: -13px;
      right: -13px;
      ${breakpointsMedia(breakpoints.tablet)} {
        border-width: 0 152px 152px 0;
        top: -22px;
        right: -22px;
      }
    }

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 96px 0 0 96px;
      border-color: transparent transparent transparent #e8336e;
      z-index: -1;
      position: absolute;
      bottom: -13px;
      left: -13px;

      ${breakpointsMedia(breakpoints.tablet)} {
        border-width: 152px 0 0 152px;
        bottom: -22px;
        left: -22px;
      }
    }
  }

  .download_asset {
    .download_asset__content {
      position: relative;
      padding: 20px 50px 20px 20px;
      background-color: #e8336e;
      color: white;
    }

    .download_asset__close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
      background-color: #fd0;
      color: #e8336e;
      font-size: 32px;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 200ms ease;
      cursor: pointer;

      &:hover {
        color: #fd0;
        background-color: #e8336e;
      }
    }

    .download_asset__title {
      .underline {
 
        font-size: 22px;
        font-weight: 800;
        color: white;
      }
    }

    .dl_popover__form {
      padding: 20px;
    }
`

export const DownloadAssetPopup = props => {
  const [isOpen, setIsOpen] = useState(false)
  const reports = useDownloadableReports()
  const { title, report } = reports?.socialMediaCovidReportPopover

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(true)
    }, 6000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  if (!report?.localFile?.publicURL) {
    return <></>
  }

  const closeClick = () => {
    setIsOpen(false)
  }

  return (
    <DownloadAssetPopupStyled
      className={classnames({
        "open-dl_popover": isOpen,
      })}
    >
      <div className="dl_popover__con">
        <div className="download_asset">
          <div className="download_asset__content">
            <div
              id="close_dl_popover"
              className="download_asset__close"
              onClick={closeClick}
              onKeyPress={closeClick}
              role="button"
              tabIndex="0"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fal"
                data-icon="times"
                className="svg-inline--fa fa-times fa-w-10 "
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path
                  fill="currentColor"
                  d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                ></path>
              </svg>
            </div>
            <p className="download_asset__title">
              {title && (
                <span
                  className="underline"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              )}
            </p>
          </div>
          <div className="download_asset__form">
            <MailChimpDownloadAsset
              downloadAsset={report.localFile.publicURL}
            />
          </div>
        </div>
      </div>
    </DownloadAssetPopupStyled>
  )
}
