import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

import Seo from "@components/Seo"
import styled from "styled-components"
import { PageHero } from "@components/sections/heros/PageHero"
import DownloadAssetBanner from "@components/sections/DownloadAssetBanner"
import { DownloadAssetPopup } from "@components/sections/DownloadAssetPopup"

const ContentStyled = styled.section`
  padding: 30px 0;
`

export default function DefaultPage({ data }) {
  const page = data.allWpPage.nodes[0]

  const { acfPageExtras } = page

  return (
    <>
      <Seo {...page.seo} />
      <Helmet bodyAttributes={{}} />

      <PageHero
        pageTitle={page.title}
        uri={page.uri}
        {...page.acfHeroImage?.pageherodata}
      />

      <ContentStyled>
        <div className="container">
          <div className="row">
            {acfPageExtras?.addDownloadReportBanner && (
              <DownloadAssetBanner
                style={{
                  marginTop: "30px",
                }}
              />
            )}

            {acfPageExtras?.addDownloadReportPopover && <DownloadAssetPopup />}

            <div
              className="col-md-12"
              dangerouslySetInnerHTML={{ __html: page.content }}
            />
          </div>
        </div>
      </ContentStyled>
    </>
  )
}

export const query = graphql`query ($slug: String!) {
  allWpPage(filter: {slug: {eq: $slug}}) {
    nodes {
      id
      uri
      title
      link
      content
      seo {
        schema {
          raw
        }
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FIXED)
            }
          }
        }
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
      }
      acfPageExtras {
        addDownloadReportBanner
        selectBanner
        addDownloadReportPopover
        selectPopover
      }
      acfHeroImage {
        pageherodata {
          blurb
          subtitle
          title
          heroImage {
            localFile {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(
                  quality: 85
                  transformOptions: {cropFocus: CENTER}
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
  }
}
`
