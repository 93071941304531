import React, { useReducer } from "react"
import styled from "styled-components"
import Loadable from "@loadable/component"
import { motion } from "framer-motion"
import classNames from "classnames"
import useDownloadableReports from "@components/utils/useDownloadableReports"

const MailChimpDownloadAsset = Loadable(() =>
  import("../forms/MailChimpDownloadAsset")
)

const DownloadAssetStyled = styled.div`
  .reportdownload {
    &__wrapper {
      background-color: #e8336e;
      color: white;
      cursor: pointer;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__title {
      .underline {
        background-size: 100% 3px;
        background-position: 0 86%;
        background-image: linear-gradient(#fd0 0%, #fd0 100%);
        font-size: 22px;
        font-weight: 800;
        color: white;
      }
    }

    &__icon {
      color: white;
      font-size: 46px;
    }
  }
`

const answerMotion = {
  rest: {
    height: 0,
    opacity: 0,
    ease: "easeOut",
    duration: 0.2,
    type: "tween",
  },
  active: {
    height: "auto",
    opacity: 1,
    transition: {
      duration: 0.25,
      type: "tween",
      ease: "easeIn",
    },
  },
}

const DownloadAssetBanner = ({ style }) => {
  const reports = useDownloadableReports()
  const [isOpen, toggle] = useReducer(isOpen => !isOpen, false)

  const { title, report } = reports?.socialMediaCovidReport

  if (!report?.localFile?.publicURL) {
    return <></>
  }

  return (
    <DownloadAssetStyled className="download-asset-con container" style={style}>
      <div className="container-constraint">
        <motion.div
          id="reportdownload"
          initial="rest"
          animate={isOpen ? "active" : "rest"}
          className={classNames(`reportdownload`, { opened: isOpen })}
        >
          <header
            className="reportdownload__wrapper"
            onClick={toggle}
            onKeyPress={toggle}
            role="button"
            tabIndex="0"
          >
            <p className="reportdownload__title">
              <span
                className="underline"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </p>
            <div className="reportdownload__icon">
              <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="file-download"
                   className="svg-inline--fa fa-file-download fa-w-12 " role="img" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 384 512">
                <path fill="currentColor"
                      d="M216 236.07c0-6.63-5.37-12-12-12h-24c-6.63 0-12 5.37-12 12v84.01h-48.88c-10.71 0-16.05 12.97-8.45 20.52l72.31 71.77c4.99 4.95 13.04 4.95 18.03 0l72.31-71.77c7.6-7.54 2.26-20.52-8.45-20.52H216v-84.01zM369.83 97.98L285.94 14.1c-9-9-21.2-14.1-33.89-14.1H47.99C21.5.1 0 21.6 0 48.09v415.92C0 490.5 21.5 512 47.99 512h287.94c26.5 0 48.07-21.5 48.07-47.99V131.97c0-12.69-5.17-24.99-14.17-33.99zM255.95 51.99l76.09 76.08h-76.09V51.99zM336 464.01H47.99V48.09h159.97v103.98c0 13.3 10.7 23.99 24 23.99H336v287.95z"></path>
              </svg>
            </div>
          </header>
          <motion.div variants={answerMotion}>
            <MailChimpDownloadAsset
              downloadAsset={report.localFile.publicURL}
            />
          </motion.div>
        </motion.div>
      </div>
    </DownloadAssetStyled>
  )
}

export default DownloadAssetBanner
